<template lang="pug">
page-structure-element(
  :element="element"
  :defaultImage="getBackground(columns[columnIndex])"
  :interactions="interactions"
  @clicked="selectRow(element.uid)"
  @mouseenter="enterRow(row)"
  @mouseleave="leaveRow(row)"
) {{ getTranslatedColumnName() }}
</template>

<script>
  import pageStructureMixin from '@/editor/mixins/sidebar/pageStructure';
  import PageStructureElement from '../PageStructureElement.vue';

  export default {
    components: { PageStructureElement },
    mixins: [pageStructureMixin],
    props: {
      element: {
        type: Object,
        required: true,
      },
      row: {
        type: Object,
        required: true,
      },
      columns: {
        type: Array,
        required: true,
      },
      columnIndex: {
        type: Number,
        required: true,
      },
      interactions: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        defaultImage: {
          background: 'transparent',
        },
      };
    },
    methods: {
      getTranslatedColumnName() {
        const COLUMN = this.$t('column');
        const COLUMN_RIGHT = this.$t('pageStructure.columnRight');
        const COLUMN_MIDDLE = this.$t('pageStructure.columnCenter');
        const COLUMN_LEFT = this.$t('pageStructure.columnLeft');

        const COLUMN_TEXT = {
          1: { 0: COLUMN },
          2: { 0: COLUMN_LEFT, 1: COLUMN_RIGHT },
          3: { 0: COLUMN_LEFT, 1: COLUMN_MIDDLE, 2: COLUMN_RIGHT },
        };
        return COLUMN_TEXT[this.columns.length][this.columnIndex];
      },
    },
  };
</script>
